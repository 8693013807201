.WriteBar {
  background: var(--vkui--color_background_modal);
}

.WriteBar--shadow {
  box-shadow: var(--vkui--elevation3);
}

.WriteBar__before,
.WriteBar__form,
.WriteBar__after {
  display: flex;
  align-items: flex-end;
}

.WriteBar__form {
  min-block-size: 52px;
}

.WriteBar__formIn {
  flex: 1;
  min-inline-size: 0;
  display: flex;
  position: relative;
}

.WriteBar__textarea {
  box-sizing: border-box;
  inline-size: 100%;
  flex: 1;
  min-inline-size: 0;
  margin: 0;
  background: transparent;
  border: 0;
  resize: none;
  color: var(--vkui--color_text_primary);
  -webkit-appearance: none;
  max-block-size: 120px;
}

.WriteBar__textarea::placeholder {
  color: var(--vkui--color_text_subhead);
  /* Для Firefox */
  opacity: 1;
}

.WriteBar__textarea:focus {
  outline: var(--vkui_internal--outline-reset);
}

.WriteBar__inlineAfter {
  display: flex;
  align-items: flex-end;
}

.WriteBar__before {
  padding-inline-start: 4px;
}

.WriteBar__after {
  padding-inline-end: 4px;
}

.WriteBar__textarea {
  block-size: 52px;
  padding-block: 16px;
  padding-inline: 12px;
}

/**
 * iOS
 */
.WriteBar--ios {
  background: var(--vkui--color_background_content);
}

.WriteBar--ios .WriteBar__before,
.WriteBar--ios .WriteBar__after {
  padding-block: 0;
  padding-inline: 4px;
}

.WriteBar--ios .WriteBar__formIn {
  box-sizing: border-box;
  background-color: var(--vkui--color_write_bar_input_background);
  border: var(--vkui--size_border--regular) solid var(--vkui--color_write_bar_input_border);
  border-radius: 18px;
  margin-block: 8px;
  margin-inline: 0;
}

.WriteBar--ios .WriteBar__formIn:first-child {
  margin-inline-start: 12px;
}

.WriteBar--ios .WriteBar__inlineAfter {
  /* чтобы правильно выровнять иконку, нужно учитывать border */
  --vkui_internal--writebar-right-gap: calc(-1 * var(--vkui--size_border--regular));
  --vkui_internal--writebar-vertical-gap: calc(var(--vkui_internal--writebar-right-gap) - 8px);

  margin-inline-end: var(--vkui_internal--writebar-right-gap);
  margin-block: var(--vkui_internal--writebar-vertical-gap);
}

.WriteBar--ios .WriteBar__textarea {
  block-size: 36px;
  padding-block: 6px 6px;
  padding-inline: 12px 0;
}
