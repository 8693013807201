.Clickable__realClickable {
  cursor: pointer;
}

.Clickable__host:focus,
.Clickable__host:focus-visible {
  outline: none;
}

.Clickable__host[disabled],
.Clickable__host[aria-disabled='true'] {
  cursor: not-allowed;
}
