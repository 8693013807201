.Popover {
  position: relative;
}

/* Создаём "Safe Zone" */
.Popover::before {
  content: '';
  position: absolute;
  inset: calc(-1 * var(--vkui_internal--popover_safe_zone_padding));
}

.Popover__in {
  position: relative;
}

.Popover__in--withStyling {
  background-color: var(--vkui--color_background_modal);
  border-radius: var(--vkui--size_border_radius--regular);
  box-shadow: var(--vkui--elevation3);
}
