.PopoutWrapper {
  inline-size: 100%;
  block-size: 100%;
  box-sizing: border-box;
  inset-inline-start: 0;
  inset-block-start: 0;
  pointer-events: none;
}

.PopoutWrapper--opened {
  pointer-events: initial;
}

.PopoutWrapper--closing {
  pointer-events: none;
}

.PopoutWrapper--fixed {
  position: fixed;
}

.PopoutWrapper__overlay {
  position: fixed;
  inset-inline-start: 0;
  inset-block-start: 0;
  inline-size: 100%;
  block-size: 100%;
  opacity: 1;
  animation: animation-full-fade-in var(--vkui--animation_duration_m) ease;
}

.PopoutWrapper--closing .PopoutWrapper__overlay {
  opacity: 0;
  transition: opacity var(--vkui--animation_duration_m) var(--vkui--animation_easing_default);
}

.PopoutWrapper--masked .PopoutWrapper__overlay {
  background: var(--vkui--color_overlay_primary);
}

.PopoutWrapper--fixed .PopoutWrapper__overlay {
  position: absolute;
}

.PopoutWrapper__container {
  inline-size: 100%;
  block-size: 100%;
  position: relative;
  display: flex;
  box-sizing: border-box;
}

.PopoutWrapper__content {
  display: flex;
  justify-content: center;
  inline-size: 100%;
  pointer-events: none;
  position: relative;
}

/* stylelint-disable-next-line selector-max-universal */
.PopoutWrapper__content > * {
  pointer-events: auto;
}

.PopoutWrapper--alignY-center .PopoutWrapper__container {
  align-items: center;
}

.PopoutWrapper--alignY-bottom .PopoutWrapper__container {
  align-items: flex-end;
}

.PopoutWrapper--alignY-top .PopoutWrapper__container {
  align-items: flex-start;
}

.PopoutWrapper--alignX-center .PopoutWrapper__container {
  justify-content: center;
}

.PopoutWrapper--alignX-left .PopoutWrapper__container {
  justify-content: flex-start;
}

.PopoutWrapper--alignX-right .PopoutWrapper__container {
  justify-content: flex-end;
}

@keyframes animation-full-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
