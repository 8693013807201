.AccordionSummary__icon {
  color: var(--vkui--color_icon_secondary);
}

.AccordionContent {
  overflow: hidden;
}

.AccordionContent__in {
  max-block-size: 0;
  transition: max-height 100ms ease-in-out;
}
