.CustomSelectOption {
  inline-size: 100%;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-block: 8px;
  padding-inline: 12px;
  user-select: none;
  cursor: pointer;
  color: var(--vkui--color_text_primary);
}

.CustomSelectOption--hierarchy {
  --vkui_internal--custom_select_option_hierarchy_level: 0;

  padding-inline-start: calc(
    var(--vkui_internal--custom_select_option_hierarchy_level) *
      var(--vkui--size_option_hierarchy--regular)
  );
}

.CustomSelectOption--sizeY-regular {
  min-block-size: 44px;
}

@media (--sizeY-regular) {
  .CustomSelectOption--sizeY-none {
    min-block-size: 44px;
  }
}

.CustomSelectOption--disabled {
  cursor: default;
  opacity: var(--vkui--opacity_disable_accessibility);
}

.CustomSelectOption--hover {
  background-color: var(--vkui--color_transparent--hover);
}

.CustomSelectOption__before {
  flex-shrink: 0;
  margin-inline-end: 7px;
}

.CustomSelectOption__main {
  flex-grow: 1;
  max-inline-size: 100%;
  min-inline-size: 0;
}

.CustomSelectOption__children {
  min-inline-size: 0;
}

.CustomSelectOption__after {
  margin-inline-start: 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.CustomSelectOption__description {
  color: var(--vkui--color_text_secondary);
}

.CustomSelectOption__selectedIcon {
  color: var(--vkui--color_icon_accent);
}

.CustomSelectOption__selectedIcon:not(:first-child) {
  margin-inline-start: 8px;
}
