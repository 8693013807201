.ScrollArrow {
  cursor: pointer;
  user-select: auto;
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: opacity 0.15s;
  transition-timing-function: var(--vkui--animation_easing_platform);
  display: flex;
  justify-content: center;
}

.ScrollArrow--direction-left,
.ScrollArrow--direction-right {
  block-size: 100%;
  flex-direction: column;
}

.ScrollArrow--direction-up,
.ScrollArrow--direction-down {
  inline-size: 100%;
  flex-direction: row;
}

.ScrollArrow__icon {
  position: relative;
  background-color: var(--vkui--color_background_modal);
  color: var(--vkui--color_icon_secondary);
  box-shadow: var(--vkui--elevation3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScrollArrow--size-m .ScrollArrow__icon {
  inline-size: 28px;
  block-size: 28px;
  border-radius: 14px;
}

.ScrollArrow--size-l .ScrollArrow__icon {
  inline-size: 40px;
  block-size: 40px;
  border-radius: 24px;
}

.ScrollArrow--direction-left {
  padding-inline-start: var(--vkui--size_base_padding_horizontal--regular, 16px);
  inset-inline-start: 0;
}

.ScrollArrow--direction-right {
  padding-inline-end: var(--vkui--size_base_padding_horizontal--regular, 16px);
  inset-inline-end: 0;
}

.ScrollArrow--direction-up {
  padding-block-start: var(--vkui--size_base_padding_horizontal--regular, 16px);
  inset-block-start: 0;
}

.ScrollArrow--direction-down {
  padding-block-end: var(--vkui--size_base_padding_horizontal--regular, 16px);
  inset-block-end: 0;
}

.ScrollArrow--direction-left .ScrollArrow__defaultIcon {
  transform: rotate(180deg);
}

.ScrollArrow--direction-up .ScrollArrow__defaultIcon {
  transform: rotate(-90deg);
}

.ScrollArrow--direction-down .ScrollArrow__defaultIcon {
  transform: rotate(90deg);
}
