.Textarea {
  position: relative;
}

.Textarea__el {
  margin: 0;
  inline-size: 100%;
  display: block;
  box-sizing: border-box;
  resize: none;
  appearance: none;
  color: var(--vkui--color_text_primary);
  padding: 12px;
  max-block-size: 204px;
  border: 0;
  z-index: var(--vkui_internal--z_index_form_field_element);
  position: relative;
  background: transparent;
}

.Textarea__el[cols] {
  inline-size: initial;
}

.Textarea--sizeY-compact .Textarea__el {
  max-block-size: 196px;
}

@media (--sizeY-compact) {
  .Textarea--sizeY-none .Textarea__el {
    max-block-size: 196px;
  }
}

.Textarea__el::placeholder {
  color: var(--vkui--color_text_secondary);
  /* Для Firefox */
  opacity: 1;
}

.Textarea__el:disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.Textarea__el:disabled::placeholder {
  color: var(--vkui--color_text_secondary);
}
