.Snackbar__body {
  box-sizing: border-box;
  padding: 12px;
  min-block-size: 56px;
  display: flex;
  align-items: center;
  background: var(--vkui--color_background_modal);
  box-shadow: var(--vkui--elevation4);
  border-radius: var(--vkui--size_card_border_radius--regular);
}

.Snackbar--mode-dark {
  background: var(--vkui--color_background_contrast_inverse);
}

.Snackbar__before {
  margin-inline-end: 12px;
}

.Snackbar__after {
  margin-inline-start: 12px;
}

.Snackbar__content {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.Snackbar--layout-vertical .Snackbar__content {
  flex-direction: column;
  align-items: flex-start;
}

.Snackbar__content-text {
  flex: 1;
  color: var(--vkui--color_text_primary);
  inline-size: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Snackbar__content-subtitle {
  color: var(--vkui--color_text_subhead);
  margin-block-start: 2px;
}

.Snackbar--mode-dark .Snackbar__content-text {
  color: var(--vkui--color_text_contrast);
}

.Snackbar--layout-horizontal .Snackbar__action {
  margin-inline-start: 12px;
  position: relative;
}

.Snackbar--layout-vertical .Snackbar__action {
  margin-block-start: 2px;
  min-block-size: initial;
}
