.Link {
  color: var(--vkui_internal--link-color, var(--vkui--color_text_link));
  text-decoration: none;
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  line-height: inherit;
  display: inline;
  border-radius: 0;
}

/* See https://www.ctrl.blog/entry/css-media-hover-samsung.html */
@media (hover: hover) and (pointer: fine) {
  .Link:hover {
    text-decoration: underline;
  }
}

.Link--has-visited:visited {
  color: var(--vkui--color_text_link_visited);
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.Link :global(.vkuiIcon) {
  margin-inline-start: 4px;
  display: inline-block;
  vertical-align: middle;
}
