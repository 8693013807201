.Calendar {
  display: flex;
  flex-direction: column;
  background: var(--vkui--color_background_modal);
  border: 0.5px solid var(--vkui--color_separator_primary);
  box-sizing: border-box;
  box-shadow: var(--vkui--elevation1);
  border-radius: 8px;
  inline-size: 305px;
  padding: 12px;
}

.Calendar__time {
  border-block-start: 1px solid var(--vkui--color_separator_primary);
  margin-block: 12px 0;
  margin-inline: -12px;
  padding-block: 12px 0;
  padding-inline: 12px;
}

.Calendar__header {
  margin-block-end: 4px;
}

.Calendar--size-s {
  inline-size: 220px;
  padding: 8px;
}

.Calendar--size-s .Calendar__header {
  margin-block-end: 0;
}
